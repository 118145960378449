.sidebar-option-active{
    background-image: linear-gradient(to right, rgba(16,112,202,0.09) 99%, #1070CA 2%);
	color:black !important;
}

.sidebar-option{
    padding: 0.5rem 1rem;
    min-height: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sidebar-option:hover{
    color:#1070CA !important;
}

.nav-option{
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.nav-option-active{
    background-color:rgba(16,112,202,0.09);
	color:#1070CA !important;
}
.bulk_edit_modal{
    height:700px;
    width:1500px;
    padding:0px;
    margin-top:64px;
    margin-left:0px
}

.bulk_edit_card{
    border-radius: 0;
    height:92vh;
    width:100vw;
    overflow-y:'scroll';
    overflow-x:'scroll'
}

.bulk_edit_preview_parent{
    display: flex;
    
}

.bulk_edit_preview_left_child{
    flex:1
}

.bulk_edit_preview_right_child{
    flex:3
}

.groups_container_bulk_edit_preview{
    flex-wrap:wrap;
    background-color:white;
    padding-bottom:16px;
    font-size: 15px;
}

.sub_cat_name_bulk_preview{
    border:1px solid #E6E9EE;
    height:44px;
    background-color:#F5F6F8;
    justify-content:space-between;
    cursor:pointer
}

.bulk_edit_of_property{
    justify-content:space-between;
    padding:16px 16px 0px 16px
}

.grey_background_property_heading{
    justify-content:space-between;
    background-color:#F5F6F8;
    margin:24px 24px 0px 24px;
    padding:8px 16px;
}

.common_property_editing{
    border: 1px solid #E6E9EE;
    border-top: 0px;
    padding: 24px;
    margin:0px 24px 24px 24px;
    font-size: 14px;
}

.individual_property_editing{
    border: 1px solid #E6E9EE;
    border-top: 0px;
    padding: 24px;
    margin:0px 24px 24px 24px;
    font-size: 14px;
}

.editing_all_skus_tabpane{
    margin-top:12px;
    background-color:white;
    height:100%;
    overflow-y:auto
}

.reset_changes{
    color:#0078FF;
    font-size:14px;
    cursor: pointer;
}

.editing_mode_div_box{
    height: 44vh;
    width: 44vh;
    background-color: white;
    border: 1px solid #B8BFCA;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    transition-duration: 0.5s;
    padding: 56px;
    cursor: pointer;
    position: relative;
}

.editing_mode_div_box:hover{
    transform: scale(1.05);
}

.choosing_editing_mode{
    background: #F7F7F7;
    border-radius: 4px;
    padding: 8px;
    margin: 0px 24px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single_sub_category{
    height: 40px;
    background: #FAFAFA;
    border: 1px solid #E4E7EB;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px 0px 20px;
    cursor: pointer;
}

.table_header{
    height: 40px;
    background: #F5F6F8;
    border: 1px solid #E4E7EB;
    width: 100%;
    display: flex;
    align-items: center;
}

.tr_style_bulk_edit{
    width: 100%;
    /* overflow-y: visible; */
}

.td_style_bulk_edit{
    height:40px;
    padding: 4px 20px;
    font-size: 14px;
    color:#22272e;
    width:30%;
    overflow-x:auto;
    /* white-space: nowrap; */
    text-transform:capitalize;
    border: 1px solid #E4E7EB;
    overflow: visible;
}

.th_style_bulk_edit{
    height:40px;
    padding: 4px 20px;
    font-size: 14px;
    color:#A7A8B2;
    width:30%;
    overflow-x:auto;
    /* white-space: nowrap; */
    text-transform:capitalize;
    border: 1px solid #E4E7EB;
    overflow: visible;
}

.input_class{
    width:100%;
    font-size:14px;
    height:100%;
    border: 0px;
    border-radius: 0px;
}

.additional_props_row:hover{
    color: #0078FF !important;
    cursor: pointer;
    /* font-size: 15px; */
}

.leaf_sku_edit_structure{
    min-height: 40px;
    display: flex;
    /* align-items: center; */
    margin: 0;
    border-bottom: 1px solid #C5C7CF;
}

.parent_sku_edit_structure{
    background-color: #F5F6F8;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
    border-bottom: 1px solid #C5C7CF;
}

.header_row_sku_structure{
    border: 1px solid #C5C7CF;
}

.header_sku_structure{
    color: #C5C7CF;
    /* border: 1px solid #C5C7CF; */
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 700;
}

.header_row_sku_structure > .header_sku_structure:not(:last-child) {
    border-right: 1px solid #C5C7CF;
}

.leaf_sku_edit_structure > div:not(:last-child) {
    border-right: 1px solid #C5C7CF;
}

.leaf_sku_edit_structure > div:nth-child(n) {
    /* height: 100%; */
    display: flex;
    align-items: center;
    min-width: 0;
    /* border-bottom: 1px solid #C5C7CF; */
}

.blue_text_edit_sku_structure{
    color: #0078FF;
    cursor: pointer;
    padding: 0px 12px;
    white-space: nowrap;
}

.edited_sku_structure_cell{
    box-shadow: 0 0 0 1px #23C16B;
}

.lines2_elipsis{
    /* display: block; */
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    /* autoprefixer: off */
}
.drag_option{
    pointer-events: initial !important;
    cursor: pointer !important;
}

.drag_option:active{
    cursor: grabbing !important;
}

.tab_selected_step{
    border: 1px solid rgba(0, 120, 255, 0.5) !important;
    color: #0078FF !important;
    background: rgba(0, 120, 255, 0.1) !important;
}

.tab_completed_step{
    border: 1px solid #29C940 !important;
    color: #29C940 !important;
}

.tab_disabled_step{
    color: #C5C7CF !important;
    border: 1px solid #C5C7CF !important;
}
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap);
@font-face{
  src: url('/assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf');
  font-family: Source_Sans_Pro-Regular;
}
@font-face{
  src: url('/assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf');
  font-family: Source_Sans_Pro-SemiBold;
}
@font-face{
  src: url('/assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf');
  font-family: Source_Sans_Pro-Bold;
}
*{
  font-family: Source_Sans_Pro-Regular;
}

:root{
  --disabled_input_background: #e9ecef;
  --width_input_sku_structure: 240px;
  --light-border-color: #e6e9ee
}

body { 
  margin: 0;
  font-family: Source_Sans_Pro-Regular;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Roboto-Regular', 'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

/*for all input */
input:focus{
    outline: none;
}
.btn:focus{
  box-shadow: 0 !important;
}

.form-control:focus {
  box-shadow: none;
}

.form-control:disabled{
  background-color: var(--disabled_input_background);
}

/*we can use for all input*/
.input_box{
  font-size: 12px;
  background-color: #FFFFFF;
  box-shadow: inset 0 0 0 1px rgba(67,90,111,0.30), inset 0 1px 2px 0 rgba(67,90,111,0.20);
  border-radius: 3px;
  color: rgba(67,90,111,0.47);
}


/*for all cards*/
.card{
    /*box-shadow: inset 0 -1px 0 0 #F2F4F6;*/
    border:0;
  box-shadow: rgba(0, 21, 41, 0.12) 0px 1px 4px 0px;
}
/*for all modal*/
.modal-xl{
  max-width:1200px !important;
}
.modal-md{
  max-width:700px !important;
}
.modal-sm{
  max-width:544px !important;
}
.modal-content{
 border:0;
 border-radius: 5px; 
 box-shadow: 0 0 1px 0 rgba(67,90,111,0.47);
}

.card-body{
  overflow-y: auto;
}


.table-responsive{
  display: flex !important; 
  height: 100% !important;
}

.full-height {
  height : 100% !important;
}



/*Table hover effects*/

.hide{
  display: none;
  cursor:pointer;
}
.invisible1{
  visibility: hidden;
}
.display_onhover:hover .x1 .hide{
  display:flex;
  color: #1890ff;
}
.display_onhover:hover .x1 .invisible1{
  display:flex;
  visibility:visible;
  color: #1890ff;
}
.display_onhover:hover .x2 .hide{
  display:inline-flex;
  color: #1890ff;
}
.display_onhover:hover .change_color{
  color: #1890ff;
}
.display_onhover:hover{
  box-shadow: inset 0 -1px 0 0 #F2F4F6;
  background-color: rgba(24, 144, 255, 0.06) !important;
}

.display_onhover:hover .display_onhover_ellipsis{
  background-color:rgb(214, 230, 240);
}

.display_onhover_red:hover .x1 .hide{
  display:flex;
  color: #1890ff;
}
.display_onhover_red:hover .x1 .invisible1{
  display:flex;
  visibility:visible;
  color: #1890ff;
}
.display_onhover_red:hover .x2 .hide{
  display:inline-flex;
  color: #1890ff;
}
.display_onhover_red:hover .change_color{
  color: #1890ff;
}
.display_onhover_red:hover{
  box-shadow: 0px -1px 0px 0px #F2F4F6 inset;
  background-color: #FFEBED !important;
}

.display_onhover_red:hover .display_onhover_ellipsis{
  background-color:#FFEBED;
}
.design_output_progress{
  position: absolute;
  margin-left: 83%;
  margin-top: 105px;
  width: 162px;
  height: 65px;
  color: white;
  background: #001628;
  border-radius: 4px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

/*Table Styling*/

.flex_property{
  display: flex;
  align-items: center;
}

.flex_center{
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.table_style { 
  flex-direction: column; 
  width: 100% !important;
}

.tr_style {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* box-shadow: inset 0 -1px 0 0 #F2F4F6; */
    border-bottom: 1px solid #F2F4F6;
}

.tr_focus{
  border: 1px solid #4597F7;
}

.tr_style_no_flex {
    width: 100%;
    /* box-shadow: inset 0 -1px 0 0 #F2F4F6; */
}

/* #card1::-webkit-scrollbar {
  display: none;
} */

#card1::-webkit-scrollbar {
  height: 8px;
  width: 0px;
}

#card2::-webkit-scrollbar {
  height: 0px;
  width: 6px;
}

#card1::-webkit-scrollbar-thumb {
  background-color: #C5C7CF;
  border-radius: 6px;
}

#card1::-webkit-scrollbar-thumb:hover {
  background-color: #676878;
  border-radius: 6px;

}

#card2::-webkit-scrollbar-thumb {
  background-color: #C5C7CF;
  border-radius: 6px;

}

#card2::-webkit-scrollbar-thumb:hover {
  background-color: #676878;
  border-radius: 6px;

}

/* #card2::-webkit-scrollbar {
  display: none;
} */

.th_style {
  flex:1 1;
  height:40px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: unset;
  border-right: 1px solid #f2f4f6;
  color:#627386;
}
.th_style_no_flex {
  height:40px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: unset;
  border-right: 1px solid #f2f4f6;
  color:#627386;
  position:-webkit-sticky;
  position:sticky;
  z-index:1;
  top:0;
  background-color: white;
}
.td_style {
  flex:1 1;
  height:40px;
  padding-left: 20px;
  font-size: 14px;
  color:#22272e;
  width:100%;
  overflow-x:auto;
  white-space: nowrap;
  /* text-transform:capitalize; */
}
.td_style_no_scroll {
  flex:1 1;
  min-height: 40px;
  padding-left: 20px;
  font-size: 14px;
  color:#22272e;
  width:100%;
  word-break: break-all;
}
.td_style_no_flex {
  height:40px;
  padding-left: 20px;
  font-size: 14px;
  color:#22272e;
  width:100%;
  overflow-x:auto;
  white-space: nowrap;
  /* text-transform:capitalize; */
  border-right: 1px solid #f2f4f6;
}

.table_text_overflow{
  width:100% !important;
  overflow-x: auto !important;
}

.div_box{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px 16px;
  width: 206px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #C5C7CF;
  border-radius: 2px;
}

.template_box{
  box-sizing: border-box;

  /* width: 60px; */
  padding: 0px 8px;
  height: 18px;
  left: 776px;
  top: 225px;

  background: #eae6e6;
  border: 1px solid #E4E4E3;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.template{
  /* width: 59px; */
  height: 15px;
  left: 776px;
  top: 226px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  /* or 220% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #676878;
}
/* ========= */
.circle-nav-button {
    height: 30px !important;
    width: 30px !important;
    line-height: 30px !important;
}

.circle-nav-button:hover {
  box-shadow: 0px 12px 45px -17px rgba(0,0,0,0.75) !important;
}

.btn-circle {
    width: 30px !important;
    height: 30px !important;
    padding: 6px 0px !important;
    border-radius: 15px !important;
    text-align: center !important;
    font-size: 12px !important;
    line-height: 1.42857 !important;
}
/* scroll bar */
/* width */
::-webkit-scrollbar {
  height: 0px;
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #002140;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* on click css change in sidebar */
.sidebar_color_change{
  background-color: white;
  color:black;
}

/*for all a */
a{
  color:#435A6F;
}

/*for all tabs*/

.nav-pills .nav-link.active{
  background: rgba(16,112,202,0.09);
  font-family: Source_Sans_Pro-SemiBold;
  height: 28px;
  display: flex;
  color: #1070CA !important;
  align-items: center;
  justify-content: center;
}

.color_for_singletab{
  color: #435A6F;
}


/*button styliling*/

.blue_button{
  height: auto;
  min-height:32px;
  min-width:88px;
  width:auto;
  background-image: linear-gradient(0deg, #007EF4 0%, #0788DE 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.20), inset 0 0 0 1px rgba(0,34,66,0.20);
  border-radius: 3px;
  font-size:12px;
  border: 0;
  color:white;

}

.red_button{
  margin-right: 12px;
  border: 0;
  height: auto;
  min-height:32px;
  min-width:88px;
  width:auto;
  font-size:12px;
  background: #FFFFFF;
  background-image: linear-gradient(0deg, red 0%, red 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
  border-radius: 3px;
  color:white;
}

.white_button{
  margin-right: 12px;
  border: 0;
  height: auto;
  min-height:32px;
  min-width:88px;
  width:auto;
  font-size:12px;
  background: #FFFFFF;
  background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
  box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
  border-radius: 3px;
  color:black !important;
  cursor: pointer;
}
.white_button:hover{
  color:black !important;
}

.delete_button{
  border: 1px #f5594e solid;
  padding: 6px 8px;
  border-radius: 4px;
}

.delete_button:hover{
  opacity: 0.7;
}

/* .donutchart{
  height: calc(100% - 80px) !important;
  width: calc(100% - 80px) !important;
} */
.donutchart-legend{
  display: none;
}
.donutchart-innertext{
  display: none;
}

for tooltip 
#TooltipExample:hover{
  display: none;
}

.flex_property_spacebetween{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/*store pricing*/
.store_terms{
  box-sizing: border-box;
  margin-top: 1.5%;
  margin-left: 2.1%;
  width: 171px;
  height: 72px;
  left: 46px;
  top: 153px;

  background: #FEFEFE;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  
  justify-content: flex-start;
}

.store_conditions{
  box-sizing: border-box;
  margin-top: 1.5%;
  margin-left: 2.4%;
  width: 389.33px;
  height: 72px;
  left: 234px;
  top: 153px;

  background: #FEFEFE;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}

.textarea_container_terms {
  position: relative;
  /* margin-top: 1.5%;
  margin-left: 2.1%; */

}
.textarea_container_terms textarea {
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  background: #FEFEFE;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  outline: none;
}
.textarea_container_terms .bold_icon {
  position: absolute;
  top: 6%;
  right: 3%;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #E2E2E2;
  cursor:pointer
}

.textarea_container_conditions {
  position: relative;
  /* margin-top: 1.5%;
  margin-left: 2.1%; */

}
.textarea_container_conditions textarea {
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  background: #FEFEFE;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  outline: none;
}
.textarea_container_conditions .bold_icon {
  position: absolute;
  top: 6%;
  right: 1.3%;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #E2E2E2;
  cursor:pointer
}

.tandc_extra_cols{
  box-sizing: border-box;
  width: 32px;
  height: 100px;
  left: 630px;
  top: 153px;
  margin-top: 2%;
  background: #FEFEFE;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}

.on_hover_option_1{
  margin-left: 70%;
  width: 16px;
  height: 11px;
  left: 529px;
  top: 253px;

  background: #FFFFFF;
}

.on_hover_option_2{
  margin-left: 89%;
  width: 43px;
  height: 32px;
  left: 521px;
  top: 261px;
}
/* for modals */
.Margin_64px_top{
  margin-top:64px;
}
.global_modal_header{
  height:40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E6E9EE;
  background-color:#FFFFFF;
  color:black;
  font-weight: 700;
}
.global_modal_footer{
  height:50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color:#FFFFFF;
}
.global_modal_body{
  height: 460px;
  overflow: auto;
  background-color:#F5F6F8;
}
.global_modal_height_for_mdlgxl{
  height:550px;
}
.global_modal_height_for_pricing_settings{
  height:577px;
  width:686px
 }
 
.global_modal_height_for_xs{
  height:300px;
}

.sales_channel_modal{
  max-width:700px !important;
  display:flex ;
  justify-content:center
}

.opacity_div{
	opacity: .5;
}

.text_ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-confirm-alert {
  z-index: 10000 !important;
}

.react-confirm-alert-overlay {
  z-index: 10000 !important;
}

.upload-3dModel-box-header {
  display: flex;
  border-bottom: 1px solid #E6E9EE;
  padding: 8px 8px 8px 16px;
}

.upload-3dModel-box-body {
  padding: 16px;
}

.upload-file-inf-header {
  display: flex;
  border-bottom: 1px solid #E6E9EE;
  padding: 8px 8px 8px 16px;
}

.upload-file-inf-body {
  padding: 16px;
}

.ellipsis_options {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;

  position: fixed;
  width: 161px;
  height: 144px;
  left: 1300px;
  top: 205px;
  margin-right: 100px;

  background-color: #edecec;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  border-radius: 5px !important;
}


#tooltip
{
  position: absolute;
  left: 0px;
  top: 0px;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 13px;
  
  display: inline-block;
}

#tooltip.right
{
  margin-left: 5px;
}

#tooltip.left
{
  margin-left: -5px;
}

#tooltip.top
{
  margin-top: -5px;
}

#tooltip.bottom
{
  margin-top: 30px;
}

#tooltip .tooltip-arrow
{
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

#tooltip.right .tooltip-arrow
{
  top: 50%;
  left: auto;
  margin-left: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}



#tooltip.top .tooltip-arrow
{
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

#tooltip.left .tooltip-arrow
{
  top: 50%;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
  right: -5px;
  left: auto;
}

#tooltip.bottom .tooltip-arrow
{
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

#tooltip .tooltip-arrow
{
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

#tooltip .tooltip-label
{
  max-width: 500px;
  height: 40px;
  min-width:150px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background:  #676878;
  cursor: pointer;
  border-radius: 100px;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #eef2f0;
  cursor: pointer;
}

.rdt {
  position: relative; }
  .rdt .rdtPicker {
    transition: all 150ms linear;
    margin-top: -20px;
    visibility: hidden;
    display: block;
    opacity: 0; }
  .rdt.rdtOpen .rdtPicker {
    opacity: 1;
    visibility: visible;
    margin-top: 0; }

.rdtPicker {
  display: none;
  position: absolute;
  padding: 20px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  border-radius: .375rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  min-width: 160px; }
  .rdtPicker:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: #ffffff;
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent; }
  .rdtPicker:after {
    border-bottom: 0.4em solid #ffffff;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    top: -5px;
    left: 10px; }

.rdtPicker {
  display: block;
  top: 40px; }

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

.rdtPicker .rdtTimeToggle {
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  visibility: hidden;
  position: relative; }
  .rdtPicker .rdtTimeToggle:before {
    visibility: visible;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 1em/1 "Nucleo";
    font-size: 18px;
    content: "\EA5E"; }

.rdtPicker table {
  width: 100%;
  margin: 0;
  border-color: #fff !important; }

.rdtPicker td,
.rdtPicker th {
  text-align: center;
  padding: 1px; }

.rdtPicker td {
  cursor: pointer; }

.rdtDay {
  font-size: .875rem;
  width: 36px;
  height: 36px;
  transition: all .15s ease;
  text-align: center;
  border: none;
  border-radius: 50%; }
  .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover, .rdtDay.start-date, .rdtDay.end-date, .rdtDay.middle-date, .rdtDay.start-date:hover, .rdtDay.end-date:hover, .rdtDay.middle-date:hover {
    background-color: #11cdef !important;
    color: #fff !important; }
  .rdtDay.start-date {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rdtDay.middle-date {
    border-radius: 0; }
  .rdtDay.end-date {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.rdtDays tr .dow {
  text-align: center;
  font-size: 12px;
  font-weight: inherit;
  padding-bottom: 5px;
  padding-top: 10px;
  font-weight: 300;
  font-size: .875rem;
  width: 20px;
  height: 20px; }

.rdtDays tr .rdtDay {
  color: #525f7f;
  font-size: .875rem; }
  .rdtDays tr .rdtDay.rdtOld, .rdtDays tr .rdtDay.rdtNew {
    color: #adb5bd;
    font-weight: 300; }

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover,
.rdtPicker .rdtTimeToggle:hover:before {
  background: #eeeeee;
  cursor: pointer; }

.rdtPicker td.rdtToday {
  position: relative; }

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker td span.rdtOld {
  color: #999999; }

.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker tr .dow {
  width: 14.2857%;
  border-bottom: none;
  font-size: .875rem;
  width: 36px;
  height: 36px;
  transition: all .15s ease;
  text-align: center;
  border: none;
  font-weight: 500;
  border-radius: .375rem; }

.rdtPicker th.rdtSwitch {
  font-weight: 500;
  border-radius: .375rem;
  font-size: .875rem;
  width: 145px;
  height: 36px;
  transition: all .15s ease;
  text-align: center;
  border: none; }

.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: .875rem;
  width: 36px;
  height: 36px;
  transition: all .15s ease;
  text-align: center;
  border: none;
  border-radius: .375rem;
  cursor: pointer; }

.rdtPicker .dow,
.rdtPicker th.rdtSwitch,
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev,
.rdtPicker .rdtTimeToggle {
  color: #525f7f; }

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

.rdtPicker thead tr:first-child th {
  cursor: pointer; }

.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

.rdtPicker button:hover {
  background-color: #eee; }

.rdtPicker thead button {
  width: 100%;
  height: 100%; }

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee; }

.rdtCounters {
  display: inline-block; }

.rdtCounters > div {
  float: left;
  width: 40px;
  font-weight: inherit;
  margin: 3px;
  border-radius: 50%; }

.rdtCounters .rdtCounterSeparator {
  width: 0;
  border: 1px solid transparent; }

.rdtCounter {
  height: 100px; }

.rdtCounter {
  width: 40px; }
  .rdtCounter .rdtCount {
    padding: 7px;
    height: 40px;
    border: 1px solid transparent; }

.rdtCounters .rdtCounter:last-child .rdtCount {
  color: #11cdef;
  border-radius: 50%;
  border: 1px solid #11cdef; }

.rdtCounterSeparator {
  padding: 7px;
  line-height: 100px; }

.rdtCounter .rdtBtn {
  line-height: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  color: #adb5bd;
  transition: all 60ms ease-in;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  /* Konqueror */
  /* Firefox */
  /* Internet Explorer/Edge */
  user-select: none; }

.rdtCounter .rdtBtn:hover {
  background: #eee;
  color: #797979; }

.rdtCounter .rdtCount {
  font-size: inherit;
  line-height: 25px; }

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

.rdtMilli input {
  width: 100%;
  font-size: inherit;
  margin-top: 37px; }

.rdtMonths,
.rdtYears {
  padding-bottom: 10px; }
  .rdtMonths .rdtMonth,
  .rdtMonths .rdtYear,
  .rdtYears .rdtMonth,
  .rdtYears .rdtYear {
    display: inline-block;
    width: 56px;
    height: 56px;
    line-height: 56px;
    margin: 3px 3px;
    cursor: pointer;
    border-radius: 50%;
    text-align: center; }
    .rdtMonths .rdtMonth.rdtActive, .rdtMonths .rdtMonth.start-date, .rdtMonths .rdtMonth.end-date, .rdtMonths .rdtMonth.middle-date,
    .rdtMonths .rdtYear.rdtActive,
    .rdtMonths .rdtYear.start-date,
    .rdtMonths .rdtYear.end-date,
    .rdtMonths .rdtYear.middle-date,
    .rdtYears .rdtMonth.rdtActive,
    .rdtYears .rdtMonth.start-date,
    .rdtYears .rdtMonth.end-date,
    .rdtYears .rdtMonth.middle-date,
    .rdtYears .rdtYear.rdtActive,
    .rdtYears .rdtYear.start-date,
    .rdtYears .rdtYear.end-date,
    .rdtYears .rdtYear.middle-date {
      background-color: #11cdef !important;
      color: #fff; }

.input-group .input-group-prepend + .rdt .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group .input-group-prepend + .rdt .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .rdt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.form-group .rdt {
  width: 1%;
  margin-bottom: 0;
  flex: 1 1 auto; }

.btn .btn-inner--icon {
  margin-right: 4px; }

.btn + .btn {
  margin-left: 4px; }

button:focus, :focus {
  outline: none; }

@keyframes show-navbar-dropdown {
  0% {
    opacity: 0;
    transition: visibility 0.25s, opacity 0.25s; }
  100% {
    opacity: 1; } }

.navbar .collapsing .navbar-toggler {
  pointer-events: none; }

.navbar .nav-item .nav-link-icon.nav-link i {
  margin-right: 4px; }

.table .avatar-group .avatar {
  margin-right: 4px; }

.table .badge i {
  margin-right: 10px; }

  .rdt1 {
    width:180px;
    margin-left: 10px;
    margin-top: 6px;
    font-size: 14px;
    height:34px;
    margin-bottom: 8px;
    margin-right: 8px;
    position: absolute;
    right: 0px;
    top:42px;
  }


  .dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #183153;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
  }
  
  .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  
  .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
  }
  
  .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
  }
  
  .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
  }
  
  .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
  }
  
  .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
  }
  
  .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
  }
  
  .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
  }
  
  .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
  }
  
  .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
  }
  
  .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
  }
  
  .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
  }
  
  .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
  }
  
  .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
  }
  
  .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
  }
  
  .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
  }
  
  @keyframes pulse0112 {
    0%,
    100% {
      transform: scale(0);
      opacity: 0.5;
    }
  
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .payment_success_check {
    animation: payment_success 2s infinite
  }

  @keyframes payment_success {
    50%{
      transform: scale(1.2);
    }

    100%{
      transform: scale(1);
    }
  }
.underline_onhover:hover {
  text-decoration: underline;
cursor: pointer;
}

.search_component_container_ideal {
  display:flex; 
  align-items:center; 
  border:1px solid #C5C7CF;
  border-radius: 2px;
  margin: 0px 16px 0px 16px;
  background-color: white;
}

.search_component_inner_container {
  width:200px; 
  margin-right:16px; 
  font-size:12px;
  padding:6px 12px 6px 12px;
  border:0px;
}

.search_component_container_ideal:focus-within {
  box-shadow: 0 0 0 0.1rem rgb(0 123 255 / 25%);
  border: 1px solid #0078FF;
}

.search_component_inner_container:focus-visible{
  outline: none;
}

.DMSans{
  font-family: 'DM Sans', sans-serif !important;
}

.downtime{
  background-color: white;
  width:100vw;
  height: 100vh;
  z-index: 100;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downtime_child{
  width: 50%;
  /* color: red; */
  height: 200px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 8px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  border-radius: 6px;
}

.standard_input{
  width: 100%;
  height: 32px;
  font-size: 14px;
}

.disable_and_reduce_opacity{
  opacity: 0.3;
  pointer-events: none;
}

.smooth_transition {
  transition: height 0.3s ease;
  overflow: hidden;
  height: 0;
}

.smooth_transition.show {
  height: 40px;
}
.percentage-symbol{
  /* content: '%'; */
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}

.cp{
  cursor: pointer;
}

.address_input{
  height: 28px;
  margin: 10px 0px;
  /* width: 100%; */
  font-size: 14px;
  border: 0px;
  padding: 0px 8px;
  border: 1px solid #E6E9EE
}

.pricing_base_plan{
  height: 180px;
  background-color: white;
  border: 2px solid #C5C7CF;
  border-radius: 6px;
  margin: 80px 12vw 0px 12vw;
  padding: 20px;
}

.pricing_base_plan:hover{
  border:2px solid #3a63d4;
}

.pricing_add_ons_container{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5vh;
  padding: 0px 20px
}

.pricing_add_on{
  height: 240px;
  background-color: white;
  border: 2px solid #C5C7CF;
  border-radius: 6px;
  width:260px;
  /* padding: 8px; */
  position: relative;
  overflow: auto;
}

.pricing_add_on:hover{
  border:2px solid #3a63d4;
}

.just_5000{
  background-color: #0078ff;
  border-radius: 6px 0px 0px 6px;
  padding: 8px;
  color: white;
  width: 140px;
  height: 80px;
}

.base_plan_features{
  background-color:#F5F6F8;
  border-radius: 0px 6px 6px 0px;
  font-size:14px; 
  text-align:left;
  padding: 8px 12px;
  height: 80px;
}

.additional_users{
  font-size: 14px;
}

.additional_users .price_indicator{
  border-radius: 20px;
  height: 28px;
  background-color: #A7A8B3;
  color: white;
  margin-left: 16px;
  padding: 0px 16px;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.add_on_name{
  height: 36px;
}

.add_on_price{
  background-color: #0078ff;
  height: 28px;
  /* font-size: 14px; */
  color: white;
  font-weight: 700;
}

.add_on_features{
  margin: 12px 16px;
  padding: 8px 8px;
  background-color: #F5F6F8;
  border-radius: 6px;
  font-size: 14px;
  height: 85%;
  width: 100%;
}

.pricing_bottom_div{
  position: absolute;
  bottom: 0px;
  left: 50%;
  border-radius: 50px 50px 0px 0px;
  box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.1);
  background-color: white;
  height: 100px;
  width: 80%;
  transform: translateX(-50%);
  z-index: 10;
  padding: 15px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pricing_bottom_div .pay_button{
  font-size: 20px;
  font-weight: 700;
  min-width: 150px;
  color: #23C16B;
  background-color: #C4F3D9;
  border: 2px solid #23C16B;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
}

.total_payable{
  font-weight: 700;
  color: #1A7945;
}

.pricingDetailsRows{
  margin-bottom: 16px;
}

.add_on{
  background-color: #F5F6F8;
  color: #676878;
  font-weight: 700;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 6px;
  margin-bottom: 10px;
}

.afw_filter_modal{
  position:absolute;
  top:40px;
  right:2px;
  width: 300px;
  /* padding:0px 20px 0px 20px; */
  background-color: white;
  font-size: 14px;
  box-shadow: rgba(0, 21, 41, 0.12) 0px 1px 4px 0px;
}

.bulk_create_td_checkbox{
	display: none;
	/* margin-right: 4px; */
}

.bulk_create_td_sno{
	/* padding-top: 8px; */
}

/* .tr_style_no_flex:hover > .td_style_no_flex, */
.tr_style_no_flex:hover .bulk_create_td_checkbox{ 
	display: inline-flex;
}

/* .tr_style_no_flex:hover > .td_style_no_flex, */
.tr_style_no_flex:hover .bulk_create_td_sno{ 
	display: none;
}

 .tr_style_no_flex{
	border-top: 1px solid rgb(242, 244, 246)
}

/*
.tr_style_no_flex:hover{
	border-top: 2px solid rgba(0, 120, 255, 0.6);
	border-left: 2px solid rgba(0, 120, 255, 0.6);
	border-right: 2px solid rgba(0, 120, 255, 0.6);
} */

/* [class*="bulk_create_border"]:hover {
	border-left: 2px solid rgba(0, 120, 255, 0.6);
	border-right: 2px solid rgba(0, 120, 255, 0.6);
}

[class*="bulk_create_border"]:first-of-type:hover {
	border-top: 2px solid rgba(0, 120, 255, 0.6);
}

[class*="bulk_create_border"]:last-of-type:hover {
	border-bottom: 2px solid rgba(0, 120, 255, 0.6);
} */
.group_name:hover input[type="checkbox"]{
	border-color: #0078ff;
}

.infurniaLogo{
	width: 400px;
	max-width: 100%;
	padding: 20px 20px;
	opacity: 0.3;
  }
.fa-sort:hover{
	color: #0078ff;
}
.infurniaLogo{
	width: 400px;
	max-width: 100%;
	padding: 20px 20px;
	opacity: 0.3;
  }

.text_ellipsis{
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.lines1_elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lines1_elipsis_limit_chars {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes ping {

  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.user_input_disabled{
  background-color: #f9f9f9 !important;
}

.suggestion-list{
  width: 60%;
  position: absolute;
  top: 32px;
  right: 0;
  text-align: left;
  font-size: 12px;
  color: black;
  padding: 4px;
  cursor: pointer;
  margin-top: 8px;
  margin-left: 8px;
  border: 1px solid #0078ff;
}

.single-suggestion{
  height: 28px;
  display: flex;
  align-items: center;
}

.single-suggestion:hover{
  background-color: rgba(24,144,255,.06);
}
.sidebar-option-active{
    background-image: linear-gradient(to right, rgba(16,112,202,0.09) 99%, #1070CA 2%);
	color:black !important;
}

.sidebar-option{
    padding: 0.5rem 1rem;
    min-height: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sidebar-option:hover{
    color:#1070CA !important;
}

.nav-option{
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
}

.nav-option-active{
    background-color:rgba(16,112,202,0.09);
	color:#1070CA !important;
}
.scale img{
    transition: all 0.5s ease;
    height: 180px;
    border-radius: 0px !important;
}
 
.scale:hover img{
    transform: scale(1.1);
}

.scale {
    border-top: 4px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    overflow: hidden;
    cursor: pointer;
}

.inventory-card:hover .scale {
    border-top: 4px solid #f7fafc;
}

.inventory-img {
    max-height: 180px;
    max-width: 100%;
    height: auto;
    width: auto !important;
    content-fit: contain;
}

.inventory-card:hover .inventory-footer {
    /*color: white !important;*/
    background-color: #f7fafc !important;
    color: #5e72e4 !important;
}

.inventory-card:hover  {
    transition: all 0.5s ease;
    box-shadow: 0px 12px 45px -17px rgba(0,0,0,0.75) !important;
}

.inventory-footer {
    padding-top: 13px !important;
    padding-bottom: 13px  !important;
    margin: 0px !important;
}

.inventory-card {
    cursor: pointer;
    border: none !important;
}

.sidebar_text:hover {
	background-color: #37414b !important;
}

.active_nav_tab {
	color: white !important ;
	background-color: #37414b !important;
	font-family: Source_Sans_Pro-SemiBold;
}

.div_position{
	position: absolute !important;
    top: 0px;
    left: 256px;
    border-radius: 0px !important;
	border:1px solid #001529;
	color:white;
    height:100%;
	width: 258px !important;
	box-shadow: 0 15px 35px rgba(50, 50, 93, 0.2), 0 5px 15px rgba(0, 0, 0, 0.17);
	background-color: black;

}

.div_category{
	height: calc(100% - 100px);
	overflow: auto;	
}

.single_div_category{
	color:#B8BFCA !important;
}

.hover_on_categories:hover{
	background: rgba(0,126,244,0.09);
	color: #ffffff !important; 
	text-decoration: none;
}

.hover_on_categories:hover .change_icon_text_color{
	color: #ffffff ; 
	text-decoration: none;
	display: block;
}

.hover_on_categories:hover .category_edit_fa{
	color: #ffffff ; 
	text-decoration: none;
	display: block !important;
}

.active_division{
	background-color: black;
}
.active_division_text{
	color: white !important;
}

.category_edit_fa{
	display:none;
	/* color:#37414B; */
}
.category_edit_fa:hover{
	color:#1070CA !important;
}
.capitalize_name{
	text-transform: capitalize !important;
}

.inf_model_outer_div{
    background-color: black;
    opacity: 0.05;
    z-index:5;
    height:100vh;
    width:100vw;
    position: fixed;
    top:0px;
    left:0px;
}

.badge_container{
    font-size:12px;
    width:100%;
    height:32px;
    position:relative;
    color: rgb(67, 90, 111);
    border-radius: 3px;
    border: 1px solid #ced4da;
    background-color:white;
    display:flex;
    align-items:center;
    overflow:auto
}

.options_search_container{
    z-index:7;
    background-color:white;
    border: 1px solid #ced4da;
    position:absolute;
    max-width:100%
}

.options_container{
    width:100%;
    max-height:150px;
    padding: 4px 8px;
    overflow:auto;
    font-size: 12px;
}

.single_option{
    display:flex;
    align-items:center;
    height:30px;
    font-size:"12px";
    padding:5px 10px;
    cursor:pointer
}

.radio_button{
    position: fixed;
    opacity: 0;
    pointer-events: none;
}

.single_option:hover{
    display:flex;
    align-items:center;
    height:30px;
    font-size:"12px";
    padding:5px 10px;
    cursor:pointer;
    box-shadow: 0 0 10px 100px #f2f2f2 inset;
}

.option_text{
    max-width:300px;
    overflow:auto;
    white-space:nowrap;
    text-transform:capitalize;
}

.single_badge{
    display:flex;
    align-items:center;
    font-size:"12px";
    margin:0px 10px;
    padding:0px 5px;
    border-radius:5px;
    background-color:#f2f2f2;
    max-width: 75%;
}
.badge_text{
    /* max-width:80px; */
    overflow:auto;
    white-space:nowrap;
    margin-right:5px;
    text-transform:capitalize;
    pointer-events: auto;
}

.badge_text:hover {
    pointer-events: none;
}

.search_input_box{
    width:100%;
    border:0;
    font-size:12px;
    padding:5px 10px;
    height:32px 
}
.dropdown_menu_for_commomtag{
    width: 100% !important;
    height: 200px !important;
    overflow: auto !important;
}
.admin_onhover:hover {
    background-color:#cce0ff !important;
}
.button:focus {
    border:0px !important
}

.design_onhover:hover {
    color:#1890ff !important;
    text-decoration: none;
}

.circle {
    display: inline-block;
      background-color: #4598F7;
      margin-right: 4px;
      border-radius: 50%;
  }
  
  .circle-inner {
    color: white;
    display: table-cell;
    vertical-align: middle; 
    text-align: center;
    text-decoration: none;
    /* height: 100px;
    width: 100px;  
    font-size: 30px; */
    width: 24px;
    height: 24px;
    text-transform: uppercase;
  }

  .bu_dropdown{
    all:unset;
  }

  .bell-icon-container {
    position: relative;
    display: inline-block;
  }
  
  .notification-dot-animate {
    position: absolute;
    top: 4.5px;
    right: 7px;
    width: 7px;
    height: 7px;
    background-color: blue;
    border-radius: 50%;
    animation: notification-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .notification-dot {
    position: absolute;
    top: 4.5px;
    right: 7px;
    width: 7px;
    height: 7px;
    background-color: blue;
    border-radius: 50%;
  }
  
  @keyframes notification-ping {
    0%,
    100% {
      transform: scale(1);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  .addon_card {
    border-radius: 6.769px;
    border: 1px solid #A7A8B2;
    background: #FAFBFB;
    display: flex;
    flex-direction: column;
}

.addon_card.addon_subscibed{
    border: 1px solid #23C16B
}

.addon_name {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 16px 0px;
}

.addon_pricing {
    background: #4597F7;
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addon_subscibed .addon_pricing{
    background: #429C6C;
}

.addon_features {
    border-radius: 6.769px;
    background: var(--System-colors-System-Colors-Grey-4, #C5C7CF48);
    flex: 1 1;
    margin: 1rem;
    padding-right: 1.5rem;
}

.uploaded-file-item:hover {
    background: #0078FF1A;
}

.uploaded-mark {
    display: flex;
}

.upload-delete-options {
    display: none;
}

.upload-file-item:hover .uploaded-mark {
    display: none;
}

.upload-file-item:hover .upload-delete-options {
    display: flex;
}

.inf-upload-icon:hover{
    color: #0078FF;
}
.infurnia_modal_header{
    height:48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E6E9EE;
    background-color:#FFFFFF;
    color: #202126;
    font-weight: 700;
    box-shadow: 0px 1px 6px 0px #0000000D;
    border-radius: 4px 4px 0px 0px;
    font-family: 'DM Sans', sans-serif;
    padding: 0px 16px;
    font-size: 16px;
  }
  .infurnia_modal_footer{
    height:52px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color:#FFFFFF;
    padding: 0px 16px;
    border-top: 1px solid #E6E9EE;
    box-shadow: 0px -1px 6px 0px #0000000D;
    border-radius: 0px 0px 4px 4px;
    font-family: 'DM Sans', sans-serif;
  }
  .infurnia_modal_body{
    /* height: 460px; */
    /* overflow: auto; */
    padding: 16px;
    background-color:#F5F6F8;
    min-height: 100px;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
  }

  .infurnia_modal_footer > :not(:last-child) {
    margin-right: 12px;
  }
  
.home_grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-gap: 20px;
    gap: 20px;
}

.home_card{
    border: 1px solid transparent;
}

.home_card_img{
    display: none;
}

.home_card:hover{
    border: 1px solid #0078FF;
    cursor: pointer;
}

.inactive_home_card{
    border: 1px solid transparent;
    opacity: 0.5 !important;
    cursor: not-allowed;
    box-shadow: 0px 4px 24px 0px #0000000D;
}

.home_card:hover > .bullets{
    display: none;
}

.home_card:hover > .home_card_img{
    display: inline;
    margin: 0 auto;
    padding-top: 5px;
}

.org_view_text{
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #676878;
}

.inf_outer_div{
    background-color: black;
    opacity: 0.05;
    z-index: 3;
    height:100vh;
    width:100vw;
    position: fixed;
    top:0px;
    left:0px;
}

.component_container_outer{
    background-color: white;
    border-radius: 3px;
    border: 1px solid #c5c7cf;
    align-items: center;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    z-index: 4;
}

.component_container_outer:hover{
    border: 1px solid #0078ff;
}

/* .component_container_outer:active{
    border: 1px solid #0078ff;
} */

.component_container_outer:focus-within{
    border: 1px solid #0078ff;
}

.search_component{
    width: 90%;
    border: 0px;
    font-size: 12px;
    color: #676878;
    padding: 5px;
    margin: 5px;
}

.single_select_selected_item{
    background-color: white;
    border: 0px;
    font-size: 12px;
    overflow: auto;
    margin: 5px;
    padding: 5px;
    width: 90%;
}

.options_container_singleselect{
    width: 100%;
    font-size: 12px;
    max-height: 150px;
    z-index: 5;
    overflow: auto;
    border: 1px solid transparent;
    position: absolute;
    background-color: white;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    padding-top: 4px;
    padding-bottom: 4px;
    /* scrollbar-color: #f5f6f8; */
}

.option_item{
    display:flex;
    z-index: 5;
    width: 90%;
    align-items:center;
    height:32px;
    font-size:"12px";
    color: #676878;
    padding:5px;
    margin-right: 5px;
    margin-left: 5px;
    cursor:pointer
}

.options_template {
    display:flex
}

.options_template:hover {
    background-color: #e6e9ee;
}

/* .option_container_singleselect:hover {
    background-color: #e6e9ee;
} */

/* .option_item:hover {
    background-color: #e6e9ee;
} */


.infurnia-table {
    width: 100%;
    border-collapse: collapse;
}

.infurnia-table th,
.infurnia-table td {
    padding: 8px;
}

.infurnia-table th {
    /* background-color: #f2f2f2; */
    font-weight: 500;
    text-align: left;
    color: #A7A8B2;
}

.infurnia-table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.infurnia-table-bordered {
    border: 1px solid #ccc;
}

.infurnia-table-dark {
    color: #fff;
    background-color: #343a40;
}

.infurnia-table-dark th,
.infurnia-table-dark td {
    border-color: #454d55;
}

.infurnia-table-hover tbody tr:hover {
    background-color: #E5F1FF;
}

.table-responsive {
    overflow-x: auto;
}

.infurnia-table-row-separated .infurnia-table-row:not(:last-child) {
    border-bottom: 1px solid var(--light-border-color);
}

.infurnia-table-column-sparated .infurnia-table-cell:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-column-sparated .infurnia-table-header:not(:last-child) {
    border-right: 1px solid var(--light-border-color);
}

.infurnia-table-header-row {
    border-bottom: 1px solid var(--light-border-color);
}
.breadcrumb {
    margin-bottom: 0px !important;
    background: transparent !important;
}

.breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0.4rem !important;
    color: #fff !important;
    content: "/" !important;
}
.arrow_onhover_1{
    color: #e6e6e6;
    cursor:pointer;
    height:10px
}
.arrow_onhover_1:hover{
    color:blue !important;
}

.arrow_onhover_2{
    color: #e6e6e6;
    cursor:pointer;
}
.arrow_onhover_2:hover{
    color:blue !important;
}

.arrow_onhover_3{
    color: #e6e6e6;
    cursor:pointer;
}
.arrow_onhover_3:hover{
    color:blue !important;
}

.arrow_onhover_4{
    color: #e6e6e6;
    cursor:pointer;
}
.arrow_onhover_4:hover{
    color:blue !important;
}

#share_branch_select{
    flex: 1 1
}
.MuiOutlinedInput-input{
	padding:8px !important
}
.card_style{
	background: #FFFFFF;
	border: 1px solid #D5DAE0;
	border-radius: 4px;
}

.sub_category_active {
	background-color:rgba(16,112,202,0.09);
	color:#1070CA !important;
	font-family: Source_Sans_Pro-SemiBold;
}

.division_hover:hover {
	color:#1070CA !important;
}
.division_active {
	background-image: linear-gradient(to right, rgba(16,112,202,0.09) 99%, #1070CA 2%);
	color:black !important;
}

.sku_dropdown{
	background-color: #FFFFFF;
	background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14) !important;
	border-radius: 3px !important;
	height:24px;
	width:24px;
	display: flex !important;
	align-items: center;
	padding:0 !important;
	margin: 0 !important;
	justify-content: center;
	margin-left: 6px !important;
	border: 0;
	font-size: 12px;
	color:#66788A; 
}

.sku_dropdown_list{
	background: #FFFFFF;
	box-shadow: 0 0 1px 0 rgba(67,90,111,0.47);
	border-radius: 5px;
	font-size: 14px;
}

.sku_dropdown_list_item{
	padding:6px 12px;
}

.group_dropdown{
	border: 1px solid #D1D7DA;
	background-color: #ffffff ;
	border-radius: 3px;
	box-shadow: none !important;
	height:27px;
	width:27px;
	display: flex !important;
	align-items: center;
	padding:0 !important;
	margin: 0 !important;
	justify-content: center;
	font-size: 12px;
}

.card_header_2{
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
}

.card_select{
	background: #FFFFFF;
	background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
	border-radius: 3px !important;
    padding: 0px !important;
    height: 24px !important;
    font-size: 12px !important;
    border: 0
}
/*card hover*/
.on_hover_card_border:hover{
	border: 1px solid rgba(0,126,244,0.32);
}

.on_hover_card_border:hover .hover_1 .hover_2{
	color:#007EF4 !important;
	display: block !important;
}

.on_hover_card_border:hover .hover_1 .hover_2 .hover_3{
	color:#007EF4 !important;
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
	border: none !important;
	display: block !important;
}


.on_hover_card_border:hover .hover_1 .hover_2 .hover_4{
	background-color: rgba(16,112,202,0.08) !important;
	background-image: unset !important;
}

.on_hover_card_border:hover .hover_1 .hover_2 .hover_x3{
	color:#007EF4 !important;
	display: block !important;
}

/*list hover*/
.on_hover_card_border_list:hover{
	background: rgba(0,126,244,0.06);
}

.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_x3{
	color:#007EF4 !important;
	display: block !important;
}
.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_3{
	color:#007EF4 !important;
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
	border: none !important;
}

.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_4{
	background-color: rgba(16,112,202,0.08) !important;
	background-image: unset !important;
}

.animate_box_shadow {
	/* box-shadow: 1px solid #0078FF; */
	border: 1px solid rgba(0,126,244,0.32);
	animation: mymove 1s infinite;
}

@keyframes mymove {
	50% {box-shadow: 0px 0px 10px 0px #0078FF;}
  }

.animate_box_shadow_small{
	border: 1px solid rgba(0,126,244,0.32);
	animation: mymove1 1s infinite;
}

@keyframes mymove1 {
	50% {box-shadow: 0px 0px 5px 0px #0078FF;}
  }
/*table tr,td*/

.list_table_tr{
	width:100%;
	border: 1px solid #F2F4F6;
	box-shadow: inset 0 -1px 0 0 #F2F4F6;
	display:flex;
	align-items:center;
	justify-content: flex-start;
	
}
.list_table_td{
	width:100%;
	height:50px;
	display:flex;
	align-items:center;
	justify-content: flex-start;
	overflow-x: auto;
	white-space: nowrap;
}

.card_size_for_sku_properties{
	height:586px !important;
	width:700px !important;
}

/*for all checkbox*/
.checkbox_style{
	background: #FFFFFF !important;
	background-image: linear-gradient(179deg, #FFFFFF 0%, #F5F6F7 100%) !important;
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14) !important;
	border-radius: 3px !important;
}

.edit_properties_dropdown{
	/* background: #FFFFFF; */
	/* background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%); */
	/* box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14); */
	/* border-radius: 3px; */
	/* width: 100%; */
	font-size: 12px;
	color:#676878;
	border-radius: 0;
	border:none;
	border-right: 1px solid #f2f4f5;
}

.form-group{
	margin-bottom: 0;
}
/*.label_margin_5px{
	margin-bottom: 3px;

}*/

.label_margin_0px{
	margin-bottom: 0px;
}

.disabled_css{
	background-color: #EEEEEE !important;
}

.sub_category_display{
	display:none !important;
}

.categories_div_for_move{
	height:40px; 
	width: 100%;
	padding-left:15px; 
	display:flex;
	align-items:center;
	color:#435A6F; 
	cursor: pointer;
	border-bottom: 1px solid #F7F7F7;
}

.categories_hover_for_move:hover {
	color:#1070CA !important;
}
.categories_active_for_move{
	background-image: linear-gradient(to right, rgba(16,112,202,0.09) 99%, #1070CA 2%);
	color:black !important;
}
.sub_categories_div_for_move{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	border-bottom:1px solid #E6E9EE;
	height:40px;
	/* padding:0px 25px; */
}

/* .division_hover:hover {
	color:#1070CA !important;
} */
.active_categories_div_for_move {
	background-image: linear-gradient(to right, rgba(16,112,202,0.09) 99%, #1070CA 2%);
	color:#1070CA !important;
}

.td_manage_sales_padding{
	padding-left: 20px;
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}
.card_loader{
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.custom-bom-step-cotent {
	margin-top: 75px;
}

.bulk_selection_exit_button{
	box-sizing: border-box;
	width: 24px;
	height: 24px;
	background: #FFFFFF;
	border: 1px solid #B6BFC8;
	color: #676878;
	border-radius: 4px;
	display: flex;
  	align-items: center;
	justify-content: center;
	margin-right: 8px;
	cursor: pointer;
}

.add_from_warehouse_button{
	cursor:pointer;
	margin-left:10px;
	height:32px;
	border: 1px solid #b8bfca;
	background-color:white;
	border-radius:4px;
	padding:8px 10px;
	display:flex;
	align-items:center
}

.advanced_pricing_content_container{
	background-color: white;
	padding: 20px;
	font-size: 14px;
}

.update_pricing_button{
	all: unset;
	background-color: #0078FF;
	color: white;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	flex: 1 1;
	font-size: 14px;
	border-radius: 4px;
	padding: 0px 16px;
	height: 32px;
}

.update_pricing_button:hover{
	background-color: #0078FF;
	opacity: 0.9;
}

.update_pricing_button:active{
	background-color: #0078FF;
	box-shadow: none;
}

.update_pricing_button:focus{
	background-color: #0078FF;
	box-shadow: none;
}

.update_pricing_button:disabled{
	background-color: #0078FF;
	opacity: 0.5;
}

.advanced_pricing_header_dropdown{
	background-color: white;
	padding: 8px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	border-bottom: 1px solid #E4E7EB;
	font-weight: 600;
	font-size: 14px;
}

.update_price_text{
	all: unset;
	color: #23C16B;
	cursor: pointer;
}

.update_price_text:hover{
	color: #23C16B;
	background-color: transparent;
}

.sku_pricing_info_container{
	background-color: #f5f6f8;
	border-radius: 6px;
	border: 1px solid #C5C7CF;
	padding: 6px 12px;
}
.inf_material_properties_individual_container{
    flex: 1 1;
    min-width: 0px;
    padding: 12px;
    height: 100%;
    overflow: auto;
}

.inf_material_properties_individual_container:not(:last-child){
    border-right: 1px solid var(--light-border-color);
}

.inf_material_properties_parent_container{
    height: 100%;
    background-color: white;
    display: flex;
    text-align: left;
    border: 1px solid var(--light-border-color);
}

.inf_material_property_row{
    display: flex;
    align-items: center;
}

.inf_material_property_row:not(:last-child){
    margin-bottom: 4px;
}

.inf_material_input_common_styles{
    background-color: white !important;
    font-size: 12px !important;
    height: calc(1.5em + 0.5rem);
    padding: 4px 8px;
    border-radius: 2px;
}
.infurnia_collapse_header{
    background-color: #f5f6f8;
	padding: 8px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	/* border-bottom: 1px solid #E4E7EB; */
	font-weight: 600;
	font-size: 12px;
}

.infurnia_collapse_container{
    background-color: white;
	font-size: 12px;
	height: 0px;
	overflow: hidden;
	transition: max-height 0.5s ease-in-out;
	max-height: 0px;
}

.infurnia_collapse_container.uncollapsed{
	border: 1px solid #E6E9EE;
	height: auto;
	max-height: 10000px;
	/* transition-delay: 0.1s; */
}

.infurnia_collapse_content{
	padding: 10px 20px;
}
.infurnia-button {
  padding: 6px 12px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-width: 72px;
  position: relative;
}

.infurnia-button:active {
  transform: scale(0.95);
}

.infurnia-button:hover {
  opacity: 0.9;
}

.infurnia-button.outline {
  background-color: transparent;
  color: #000;
  border: 1px solid;
}

.infurnia-button.small {
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.25rem;
}

.infurnia-button.medium {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.25rem;
}

.infurnia-button.large {
  padding: 8px 16px;
  font-size: 18px;
  line-height: 1.75rem;
}

.infurnia-button.primary {
  background-color: #007bff;
  color: #fff;
  border: 1px solid #0078ff;
}

.infurnia-button.secondary {
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid
}

.infurnia-button.success {
  background-color: #28a745;
  color: #fff;
}

.infurnia-button.warning {
  background-color: #f9dade;
  color: #ff5247;
}

.infurnia-button.ghost.primary {
  background-color: transparent;
  color: #007bff;
  border: none;
}

.infurnia-button.ghost.secondary {
  background-color: transparent;
  color: #000000;
  border: none
}

.infurnia-button.ghost.success {
  background-color: transparent;
  color: #28a745;
  border: none;
}

.infurnia-button.ghost.warning {
  background-color: transparent;
  color: #ff5247;
  border: none
}

.infurnia-button.outline.primary {
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
}

.infurnia-button.outline.secondary {
  background-color: transparent;
  color: #000000;
  border: 1px solid #000000
}

.infurnia-button.outline.success {
  background-color: transparent;
  color: #28a745;
  border: 1px solid #28a745;
}

.infurnia-button.outline.warning {
  background-color: transparent;
  color: #ff5247;
  border: 1px solid #f9dade
}
.inf_badge{
    padding: 0px 4px;
    border-radius: 6px;
}

.inf_blue_badge {
    background-color: rgba(59, 130, 246, 0.10);
    color: rgb(59 130 246);
    border: 1px rgb(59 130 246) solid;
}

.inf_yellow_badge {
    background-color: rgba(234, 179, 8, 0.10);
    color: rgb(234 179 8);
    border: 1px rgb(234 179 8) solid;
}

.inf_green_badge {
    background-color: rgba(34, 197, 94, 0.10);
    color: rgb(34 197 94);
    border: 1px rgb(34 197 94) solid;
}

.inf_red_badge {
    background-color: rgba(239, 68, 68, 0.10);
    color: rgb(239 68 68);
    border: 1px rgb(239 68 68) solid;
}
.inf_model_outer_div_new{
    background-color: black;
    opacity: 0.05;
    z-index:3;
    height:100vh;
    width:100vw;
    position: fixed;
    top:0px;
    left:0px;
}

.badge_container_new{
    font-size:12px;
    width:90%;
    height: auto;
    /* max-height: 115px; */
    position:relative;
    color: rgb(67, 90, 111);
    border-radius: 3px;
    border: 1px solid #ced4da;
    background-color:white;
    display:flex;
    flex-wrap: wrap;
    align-items:center;
    overflow:auto;
    z-index: 4;
}

.badge_container_outer_new{
    font-size:12px;
    height: auto;
    min-height: 40px;
    /* max-height: 115px; */
    position:relative;
    color: rgb(67, 90, 111);
    border-radius: 3px;
    border: 1px solid #ced4da;
    background-color:white;
    display:flex;
    /* flex-wrap: wrap; */
    align-items:center;
    overflow:auto;
    z-index: 4;
}

.options_search_container_new{
    z-index:5;
    background-color:white;
    border: 1px solid #ced4da;
    position:absolute;
    max-width:100%
}

.options_container_new{
    width:100%;
    max-height:150px;
    overflow:auto;
    font-size: 12px;
}

.single_option_new{
    display:flex;
    align-items:center;
    height:30px;
    font-size:"12px";
    padding:5px 10px;
    cursor:pointer 
}

.single_option_new:hover {
    background-color:#ced4da;
}

.option_text_new{
    max-width:300px;
    overflow:auto;
    white-space:nowrap;
    text-transform:capitalize;
}

.single_badge_new{
    display:flex;
    align-items:center;
    font-size:"12px";
    margin:5px 10px;
    padding:5px 5px;
    border-radius:5px;
    background-color:#f2f2f2
}
.badge_text_new{
    max-width:80px;
    overflow:auto;
    white-space:nowrap;
    margin-right:5px;
    text-transform:capitalize
}

.search_input_box_new{
    width:90%;
    border:0;
    font-size:12px;
    padding:5px 10px;
    height:32px 
}
.linked_sku_unowned_container{
    padding: 6px 12px;
    border: 1px solid #ced4da;
    background-color: white;
    opacity: 0.5;
    width: 100%;
    overflow: hidden;
    display: flex;
}

.single_badge_linked_sku{
    padding: 0px 5px;
    overflow: hidden;
    white-space: nowrap;
    background-color: #f2f2f2;
}

.input_container_publish_sheet{
	border: 1px solid #c5c7cf;
	height: 32px;
	background-color: white;
}

.add_option_publish{
	background: rgba(16, 112, 202, 0.08);
	border-radius: 3px;
	height: 20px;
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.add_option_container_publish{
	display: flex;
	align-items: center;
	padding: 0px 6px;
	border-left: 1px solid #c5c7cf;
	height: 100%;
}

.disable_with_haziness {
    opacity: 0.7;
    pointer-events: none;
}

.sku-enter {
    opacity: 0.01;
}
  
.sku-enter.sku-enter-active {
    opacity: 1;
    transition: opacity 2500ms ease-in;
}
  
.sku-leave {
    opacity: 1;
}
  
.sku-leave.sku-leave-active {
    opacity: 0.01;
    transition: opacity 1300ms ease-in;
}

.item-enter {
    opacity: 0;
}
.item-enter-active {
    opacity: 1;
    transition: all 400ms ease-in;
}
.item-exit {
    opacity: 1;
}
.item-exit-active {
    opacity: 0;
    transition: all 400ms ease-in;
}


.next-step-enter {
    opacity: 0;
    transform: scale(0.9);
    transform: translateX(10%);
}
.next-step-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.next-step-exit {
    opacity: 1;
    transform: translateX(0);
}
.next-step-exit-active {
    transform: translateX(-10%);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}


.previous-step-enter {
    opacity: 0;
    transform: scale(0.9);
    transform: translateX(-10%);
}
.previous-step-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.previous-step-exit {
    opacity: 1;
    transform: translateX(0);
}
.previous-step-exit-active {
    transform: translateX(10%);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}
/* .single_column:hover{
    background-color: #cccccc !important;
} */
.sku-selection-group-card{
    flex-basis: 50%;
    padding: 8px;
    margin-bottom: 16px;
}

.sku-selection-group-card-inner{
    display: flex;
    border: 1px solid #E6E9EE;
    border-radius: 8px;
    height: 100px;
    width: 100%;
}

.sku_selection_sidebar{
    border-right: 1px solid #E6E9EE;
}

.sku_selection_sub_categories_nav_container{
    padding: 0.5rem;
    border-bottom: 1px solid #E6E9EE;
    background-color: white;
}

.highlight-selection{
    animation: animate_shadow_blue 1s infinite;
}

@keyframes animate_shadow_blue {
	50% {box-shadow: 0px 0px 4px 0px #0078FF;}
  }
.bulk_edit_modal{
    height:700px;
    width:1500px;
    padding:0px;
    margin-top:64px;
    margin-left:0px
}

.bulk_edit_card{
    border-radius: 0;
    height:92vh;
    width:100vw;
    overflow-y:'scroll';
    overflow-x:'scroll'
}

.bulk_edit_preview_parent{
    display: flex;
    
}

.bulk_edit_preview_left_child{
    flex:1 1
}

.bulk_edit_preview_right_child{
    flex:3 1
}

.groups_container_bulk_edit_preview{
    flex-wrap:wrap;
    background-color:white;
    padding-bottom:16px;
    font-size: 15px;
}

.sub_cat_name_bulk_preview{
    border:1px solid #E6E9EE;
    height:44px;
    background-color:#F5F6F8;
    justify-content:space-between;
    cursor:pointer
}

.bulk_edit_of_property{
    justify-content:space-between;
    padding:16px 16px 0px 16px
}

.grey_background_property_heading{
    justify-content:space-between;
    background-color:#F5F6F8;
    margin:24px 24px 0px 24px;
    padding:8px 16px;
}

.common_property_editing{
    border: 1px solid #E6E9EE;
    border-top: 0px;
    padding: 24px;
    margin:0px 24px 24px 24px;
    font-size: 14px;
}

.individual_property_editing{
    border: 1px solid #E6E9EE;
    border-top: 0px;
    padding: 24px;
    margin:0px 24px 24px 24px;
    font-size: 14px;
}

.editing_all_skus_tabpane{
    margin-top:12px;
    background-color:white;
    height:100%;
    overflow-y:auto
}

.reset_changes{
    color:#0078FF;
    font-size:14px;
    cursor: pointer;
}

.editing_mode_div_box{
    height: 44vh;
    width: 44vh;
    background-color: white;
    border: 1px solid #B8BFCA;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    transition-duration: 0.5s;
    padding: 56px;
    cursor: pointer;
    position: relative;
}

.editing_mode_div_box:hover{
    transform: scale(1.05);
}

.choosing_editing_mode{
    background: #F7F7F7;
    border-radius: 4px;
    padding: 8px;
    margin: 0px 24px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single_sub_category{
    height: 40px;
    background: #FAFAFA;
    border: 1px solid #E4E7EB;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 16px 0px 20px;
    cursor: pointer;
}

.table_header{
    height: 40px;
    background: #F5F6F8;
    border: 1px solid #E4E7EB;
    width: 100%;
    display: flex;
    align-items: center;
}

.tr_style_bulk_edit{
    width: 100%;
    /* overflow-y: visible; */
}

.td_style_bulk_edit{
    height:40px;
    padding: 4px 20px;
    font-size: 14px;
    color:#22272e;
    width:30%;
    overflow-x:auto;
    /* white-space: nowrap; */
    text-transform:capitalize;
    border: 1px solid #E4E7EB;
    overflow: visible;
}

.th_style_bulk_edit{
    height:40px;
    padding: 4px 20px;
    font-size: 14px;
    color:#A7A8B2;
    width:30%;
    overflow-x:auto;
    /* white-space: nowrap; */
    text-transform:capitalize;
    border: 1px solid #E4E7EB;
    overflow: visible;
}

.input_class{
    width:100%;
    font-size:14px;
    height:100%;
    border: 0px;
    border-radius: 0px;
}

.additional_props_row:hover{
    color: #0078FF !important;
    cursor: pointer;
    /* font-size: 15px; */
}

.leaf_sku_edit_structure{
    min-height: 40px;
    display: flex;
    /* align-items: center; */
    margin: 0;
    border-bottom: 1px solid #C5C7CF;
}

.parent_sku_edit_structure{
    background-color: #F5F6F8;
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
    border-bottom: 1px solid #C5C7CF;
}

.header_row_sku_structure{
    border: 1px solid #C5C7CF;
}

.header_sku_structure{
    color: #C5C7CF;
    /* border: 1px solid #C5C7CF; */
    padding-top: 4px;
    padding-bottom: 4px;
    font-weight: 700;
}

.header_row_sku_structure > .header_sku_structure:not(:last-child) {
    border-right: 1px solid #C5C7CF;
}

.leaf_sku_edit_structure > div:not(:last-child) {
    border-right: 1px solid #C5C7CF;
}

.leaf_sku_edit_structure > div:nth-child(n) {
    /* height: 100%; */
    display: flex;
    align-items: center;
    min-width: 0;
    /* border-bottom: 1px solid #C5C7CF; */
}

.blue_text_edit_sku_structure{
    color: #0078FF;
    cursor: pointer;
    padding: 0px 12px;
    white-space: nowrap;
}

.edited_sku_structure_cell{
    box-shadow: 0 0 0 1px #23C16B;
}

.lines2_elipsis{
    /* display: block; */
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    /* autoprefixer: off */
}
.groupListCard{
    height: 100%;
}

.groupListCardContainer{
    background-color: #F5F6F8;
    height: 200px;
}

.skuListContainer{
    background-color: white;
    border-radius: 4px;
    padding: 4px 8px;
    /* height: 100%; */
    overflow: auto;
}

.groupNameContainer{
    padding: 0px 8px;
    font-size: 14px;
}

.skuListItem{
    margin-bottom: 8px;
    font-size: 14px;
}

.bulkskuBackgroundPlaceholder{
    background: #ffffff url('/assets/bulk_edit/bulkskuBackground.svg') no-repeat center center;
    /* padding: 8px; */
    background-size: contain;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.react-confirm-alert-overlay{
    background: rgba(0, 0, 0, 0.5) !important;
}

.saleschannel_dropdown{
    font-size: 14px;
    /* padding-left: 20px; */
    font-weight: 400;
    padding: 6px 12px 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: rgb(242, 244, 246); */
    /* border: 1px solid lightgray; */
    /* border-radius: 4px; */
    /* cursor: pointer; */
    margin-bottom: 12px;
    /* position: sticky;
    top: 40px; */

}

.add_remove_sales_channel{
    padding: 0px 12px;
    cursor: pointer;
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 2px;
}

.saleschannel_tab{
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px;
}

.saleschannel_tab_container{
    display: flex;
    align-items: center;
    /* position: sticky;
    top:20px; */
    justify-content: space-between;
}

.note_sales_channel_deletion{
    padding: 8px;
    background-color: white;
    border-radius: 6px;
}
.drag_option{
    pointer-events: initial !important;
    cursor: pointer !important;
}

.drag_option:active{
    cursor: grabbing !important;
}

.tab_selected_step{
    border: 1px solid rgba(0, 120, 255, 0.5) !important;
    color: #0078FF !important;
    background: rgba(0, 120, 255, 0.1) !important;
}

.tab_completed_step{
    border: 1px solid #29C940 !important;
    color: #29C940 !important;
}

.tab_disabled_step{
    color: #C5C7CF !important;
    border: 1px solid #C5C7CF !important;
}

.drag_option{
    pointer-events: initial !important;
    cursor: pointer !important;
}

.drag_option:active{
    cursor: grabbing !important;
}

.tab_selected_step{
    border: 1px solid rgba(0, 120, 255, 0.5) !important;
    color: #0078FF !important;
    background: rgba(0, 120, 255, 0.1) !important;
}

.tab_completed_step{
    border: 1px solid #29C940 !important;
    color: #29C940 !important;
}

.tab_disabled_step{
    color: #C5C7CF !important;
    border: 1px solid #C5C7CF !important;
}
.feature_category_item{
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--light-border-color);
    padding: 0.5rem 1rem;
    font-size: 12px;
}
.user_role_badge{
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 4px;
    background-color: lightgray;
    text-transform: capitalize;
    white-space: nowrap;
}
.invalid_input_cam{
    border: 1px solid red
}

.infurnia_search_component_container_ideal {
    display:flex; 
    align-items:center; 
    border:1px solid #C5C7CF;
    border-radius: 2px;
    margin: 0px 16px 0px 16px;
    background-color: white;
}

.infurnia_search_component_inner_container {
    /* width:200px;  */
    flex: 1 1;
    min-width: 0;
    margin-right:16px; 
    font-size:12px;
    padding:6px 12px 6px 12px;
    border:0px;
}
.filler_empty_state {
    min-height: 32px;
    background: #F5F6F8;
    border: 1px dashed #C5C7CF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    color: #A7A8B2;
}

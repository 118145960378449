.MuiOutlinedInput-input{
	padding:8px !important
}
.card_style{
	background: #FFFFFF;
	border: 1px solid #D5DAE0;
	border-radius: 4px;
}

.sub_category_active {
	background-color:rgba(16,112,202,0.09);
	color:#1070CA !important;
	font-family: Source_Sans_Pro-SemiBold;
}

.division_hover:hover {
	color:#1070CA !important;
}
.division_active {
	background-image: linear-gradient(to right, rgba(16,112,202,0.09) 99%, #1070CA 2%);
	color:black !important;
}

.sku_dropdown{
	background-color: #FFFFFF;
	background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14) !important;
	border-radius: 3px !important;
	height:24px;
	width:24px;
	display: flex !important;
	align-items: center;
	padding:0 !important;
	margin: 0 !important;
	justify-content: center;
	margin-left: 6px !important;
	border: 0;
	font-size: 12px;
	color:#66788A; 
}

.sku_dropdown_list{
	background: #FFFFFF;
	box-shadow: 0 0 1px 0 rgba(67,90,111,0.47);
	border-radius: 5px;
	font-size: 14px;
}

.sku_dropdown_list_item{
	padding:6px 12px;
}

.group_dropdown{
	border: 1px solid #D1D7DA;
	background-color: #ffffff ;
	border-radius: 3px;
	box-shadow: none !important;
	height:27px;
	width:27px;
	display: flex !important;
	align-items: center;
	padding:0 !important;
	margin: 0 !important;
	justify-content: center;
	font-size: 12px;
}

.card_header_2{
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
}

.card_select{
	background: #FFFFFF;
	background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%);
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14);
	border-radius: 3px !important;
    padding: 0px !important;
    height: 24px !important;
    font-size: 12px !important;
    border: 0
}
/*card hover*/
.on_hover_card_border:hover{
	border: 1px solid rgba(0,126,244,0.32);
}

.on_hover_card_border:hover .hover_1 .hover_2{
	color:#007EF4 !important;
	display: block !important;
}

.on_hover_card_border:hover .hover_1 .hover_2 .hover_3{
	color:#007EF4 !important;
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
	border: none !important;
	display: block !important;
}


.on_hover_card_border:hover .hover_1 .hover_2 .hover_4{
	background-color: rgba(16,112,202,0.08) !important;
	background-image: unset !important;
}

.on_hover_card_border:hover .hover_1 .hover_2 .hover_x3{
	color:#007EF4 !important;
	display: block !important;
}

/*list hover*/
.on_hover_card_border_list:hover{
	background: rgba(0,126,244,0.06);
}

.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_x3{
	color:#007EF4 !important;
	display: block !important;
}
.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_3{
	color:#007EF4 !important;
	background: rgba(16,112,202,0.08);
	border-radius: 3px;
	border: none !important;
}

.on_hover_card_border_list:hover .hover_1 .hover_2 .hover_4{
	background-color: rgba(16,112,202,0.08) !important;
	background-image: unset !important;
}

.animate_box_shadow {
	/* box-shadow: 1px solid #0078FF; */
	border: 1px solid rgba(0,126,244,0.32);
	animation: mymove 1s infinite;
}

@keyframes mymove {
	50% {box-shadow: 0px 0px 10px 0px #0078FF;}
  }

.animate_box_shadow_small{
	border: 1px solid rgba(0,126,244,0.32);
	animation: mymove1 1s infinite;
}

@keyframes mymove1 {
	50% {box-shadow: 0px 0px 5px 0px #0078FF;}
  }
/*table tr,td*/

.list_table_tr{
	width:100%;
	border: 1px solid #F2F4F6;
	box-shadow: inset 0 -1px 0 0 #F2F4F6;
	display:flex;
	align-items:center;
	justify-content: flex-start;
	
}
.list_table_td{
	width:100%;
	height:50px;
	display:flex;
	align-items:center;
	justify-content: flex-start;
	overflow-x: auto;
	white-space: nowrap;
}

.card_size_for_sku_properties{
	height:586px !important;
	width:700px !important;
}

/*for all checkbox*/
.checkbox_style{
	background: #FFFFFF !important;
	background-image: linear-gradient(179deg, #FFFFFF 0%, #F5F6F7 100%) !important;
	box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14) !important;
	border-radius: 3px !important;
}

.edit_properties_dropdown{
	/* background: #FFFFFF; */
	/* background-image: linear-gradient(0deg, #F4F5F7 0%, #FFFFFF 100%); */
	/* box-shadow: inset 0 -1px 1px 0 rgba(67,90,111,0.09), inset 0 0 0 1px rgba(67,90,111,0.14); */
	/* border-radius: 3px; */
	/* width: 100%; */
	font-size: 12px;
	color:#676878;
	border-radius: 0;
	border:none;
	border-right: 1px solid #f2f4f5;
}

.form-group{
	margin-bottom: 0;
}
/*.label_margin_5px{
	margin-bottom: 3px;

}*/

.label_margin_0px{
	margin-bottom: 0px;
}

.disabled_css{
	background-color: #EEEEEE !important;
}

.sub_category_display{
	display:none !important;
}

.categories_div_for_move{
	height:40px; 
	width: 100%;
	padding-left:15px; 
	display:flex;
	align-items:center;
	color:#435A6F; 
	cursor: pointer;
	border-bottom: 1px solid #F7F7F7;
}

.categories_hover_for_move:hover {
	color:#1070CA !important;
}
.categories_active_for_move{
	background-image: linear-gradient(to right, rgba(16,112,202,0.09) 99%, #1070CA 2%);
	color:black !important;
}
.sub_categories_div_for_move{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	border-bottom:1px solid #E6E9EE;
	height:40px;
	/* padding:0px 25px; */
}

/* .division_hover:hover {
	color:#1070CA !important;
} */
.active_categories_div_for_move {
	background-image: linear-gradient(to right, rgba(16,112,202,0.09) 99%, #1070CA 2%);
	color:#1070CA !important;
}

.td_manage_sales_padding{
	padding-left: 20px;
}

@keyframes shine {
	to {
		background-position-x: -200%;
	}
}
.card_loader{
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.custom-bom-step-cotent {
	margin-top: 75px;
}

.bulk_selection_exit_button{
	box-sizing: border-box;
	width: 24px;
	height: 24px;
	background: #FFFFFF;
	border: 1px solid #B6BFC8;
	color: #676878;
	border-radius: 4px;
	display: flex;
  	align-items: center;
	justify-content: center;
	margin-right: 8px;
	cursor: pointer;
}

.add_from_warehouse_button{
	cursor:pointer;
	margin-left:10px;
	height:32px;
	border: 1px solid #b8bfca;
	background-color:white;
	border-radius:4px;
	padding:8px 10px;
	display:flex;
	align-items:center
}

.advanced_pricing_content_container{
	background-color: white;
	padding: 20px;
	font-size: 14px;
}

.update_pricing_button{
	all: unset;
	background-color: #0078FF;
	color: white;
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
	flex: 1;
	font-size: 14px;
	border-radius: 4px;
	padding: 0px 16px;
	height: 32px;
}

.update_pricing_button:hover{
	background-color: #0078FF;
	opacity: 0.9;
}

.update_pricing_button:active{
	background-color: #0078FF;
	box-shadow: none;
}

.update_pricing_button:focus{
	background-color: #0078FF;
	box-shadow: none;
}

.update_pricing_button:disabled{
	background-color: #0078FF;
	opacity: 0.5;
}

.advanced_pricing_header_dropdown{
	background-color: white;
	padding: 8px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	border-bottom: 1px solid #E4E7EB;
	font-weight: 600;
	font-size: 14px;
}

.update_price_text{
	all: unset;
	color: #23C16B;
	cursor: pointer;
}

.update_price_text:hover{
	color: #23C16B;
	background-color: transparent;
}

.sku_pricing_info_container{
	background-color: #f5f6f8;
	border-radius: 6px;
	border: 1px solid #C5C7CF;
	padding: 6px 12px;
}
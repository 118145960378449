.filler_empty_state {
    min-height: 32px;
    background: #F5F6F8;
    border: 1px dashed #C5C7CF;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    color: #A7A8B2;
}
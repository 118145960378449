.disable_with_haziness {
    opacity: 0.7;
    pointer-events: none;
}

.sku-enter {
    opacity: 0.01;
}
  
.sku-enter.sku-enter-active {
    opacity: 1;
    transition: opacity 2500ms ease-in;
}
  
.sku-leave {
    opacity: 1;
}
  
.sku-leave.sku-leave-active {
    opacity: 0.01;
    transition: opacity 1300ms ease-in;
}

.item-enter {
    opacity: 0;
}
.item-enter-active {
    opacity: 1;
    transition: all 400ms ease-in;
}
.item-exit {
    opacity: 1;
}
.item-exit-active {
    opacity: 0;
    transition: all 400ms ease-in;
}


.next-step-enter {
    opacity: 0;
    transform: scale(0.9);
    transform: translateX(10%);
}
.next-step-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.next-step-exit {
    opacity: 1;
    transform: translateX(0);
}
.next-step-exit-active {
    transform: translateX(-10%);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}


.previous-step-enter {
    opacity: 0;
    transform: scale(0.9);
    transform: translateX(-10%);
}
.previous-step-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.previous-step-exit {
    opacity: 1;
    transform: translateX(0);
}
.previous-step-exit-active {
    transform: translateX(10%);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}
.scale img{
    transition: all 0.5s ease;
    height: 180px;
    border-radius: 0px !important;
}
 
.scale:hover img{
    transform: scale(1.1);
}

.scale {
    border-top: 4px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    overflow: hidden;
    cursor: pointer;
}

.inventory-card:hover .scale {
    border-top: 4px solid #f7fafc;
}

.inventory-img {
    max-height: 180px;
    max-width: 100%;
    height: auto;
    width: auto !important;
    content-fit: contain;
}

.inventory-card:hover .inventory-footer {
    /*color: white !important;*/
    background-color: #f7fafc !important;
    color: #5e72e4 !important;
}

.inventory-card:hover  {
    transition: all 0.5s ease;
    box-shadow: 0px 12px 45px -17px rgba(0,0,0,0.75) !important;
}

.inventory-footer {
    padding-top: 13px !important;
    padding-bottom: 13px  !important;
    margin: 0px !important;
}

.inventory-card {
    cursor: pointer;
    border: none !important;
}

.admin_onhover:hover {
    background-color:#cce0ff !important;
}
.button:focus {
    border:0px !important
}

.design_onhover:hover {
    color:#1890ff !important;
    text-decoration: none;
}

.circle {
    display: inline-block;
      background-color: #4598F7;
      margin-right: 4px;
      border-radius: 50%;
  }
  
  .circle-inner {
    color: white;
    display: table-cell;
    vertical-align: middle; 
    text-align: center;
    text-decoration: none;
    /* height: 100px;
    width: 100px;  
    font-size: 30px; */
    width: 24px;
    height: 24px;
    text-transform: uppercase;
  }

  .bu_dropdown{
    all:unset;
  }

  .bell-icon-container {
    position: relative;
    display: inline-block;
  }
  
  .notification-dot-animate {
    position: absolute;
    top: 4.5px;
    right: 7px;
    width: 7px;
    height: 7px;
    background-color: blue;
    border-radius: 50%;
    animation: notification-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  .notification-dot {
    position: absolute;
    top: 4.5px;
    right: 7px;
    width: 7px;
    height: 7px;
    background-color: blue;
    border-radius: 50%;
  }
  
  @keyframes notification-ping {
    0%,
    100% {
      transform: scale(1);
      opacity: 0.5;
    }
    50% {
      transform: scale(1.5);
      opacity: 0;
    }
  }

  .addon_card {
    border-radius: 6.769px;
    border: 1px solid #A7A8B2;
    background: #FAFBFB;
    display: flex;
    flex-direction: column;
}

.addon_card.addon_subscibed{
    border: 1px solid #23C16B
}

.addon_name {
    color: #000;
    text-align: center;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 16px 0px;
}

.addon_pricing {
    background: #4597F7;
    color: #FFF;
    font-size: 14px;
    font-weight: 700;
    min-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addon_subscibed .addon_pricing{
    background: #429C6C;
}

.addon_features {
    border-radius: 6.769px;
    background: var(--System-colors-System-Colors-Grey-4, #C5C7CF48);
    flex: 1;
    margin: 1rem;
    padding-right: 1.5rem;
}